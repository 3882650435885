import React from 'react';
import './App.css';
import { BrowserRouter ,Routes,Route } from "react-router-dom";
import Main from './modules/main';
import Home from './modules/home';
import Acheive from './modules/acheive';
import Educate from './modules/edu';
import Projects from './modules/project';
import Skills from './modules/skills';


const App = () => {
  return (
    <div>
       <BrowserRouter>
          <Main>
            <Routes>
              <Route path="/" element = {<Home/>} />
              <Route path="/portfolio/Home" element = {<Home/>} />
              <Route path="/portfolio/Achievements" element = {<Acheive/>} />
              <Route path="/portfolio/education" element = {<Educate/>} />
              <Route path="/portfolio/projects" element = {<Projects/>} /> 
              <Route path="/portfolio/skills" element = {<Skills/>} />
              <Route path="*" element = {<div>No Found </div>} />
            </Routes>
          </Main>
       </BrowserRouter>
    </div>
  );
}

export default App;


// npm create vite@latest react-app -- --template react


// home 
// education
// acheivements 
// skills
// projects

// #F9F5F6    #F8E8EE    #FDCEDF 