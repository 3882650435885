import React from "react"; 
import "./skills.css";
import { SiLeetcode } from "react-icons/si";
import { RiFilePaper2Line } from "react-icons/ri";
import { SiCodechef } from "react-icons/si";
import { SiCodeforces } from "react-icons/si";
import { FiMail } from "react-icons/fi";
import { SiGeeksforgeeks } from "react-icons/si";
import { SiHackerrank } from "react-icons/si";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";

const Skills = () => {
    return (
        <div className="h-container">
            <div className="s-wrapper"> 
                <div className="s-left">
                    <div className="card1">Data Structures and Algorithms - Problem Solving , Critical Thinking</div> 
                    <div className="card1">Object Oriented Programming with C++, Java</div> 
                    <div className="card1">Spring Boot,Nodejs, MySQL, React , Git , Natural Language Processing (Machine learning)</div>          
                    <div className="card1">Good verbal and written Communication</div>
                </div>
                <div className="s-right">
                    <div className="butt">
                        <button className="buttlink" onClick={() => window.open("https://leetcode.com/jyoshna_570/", "_blank")}>
                        <div className="icon-container">   <SiLeetcode className="icon"/>   </div>
                          <span>LeetCode</span>
                        </button>
                    </div>
                    <div className="butt">
                        <button className="buttlink" onClick={() => window.open("https://drive.google.com/file/d/1jT4xQmJblWgxa2Fvtrp-Ez3705khcyys/view?usp=sharing", "_blank")}>
                        <div className="icon-container">   <RiFilePaper2Line  className="icon" />  </div>
                        <span>Resume</span>
                        </button>
                    </div>
                    <div className="butt">
                        <button className="buttlink" onClick={() => window.open("https://www.codechef.com/users/cse_570", "_blank")}>
                        <div className="icon-container"><SiCodechef className="icon" />   </div>
                         <span>Codechef</span>
                        </button>
                    </div>
                    <div className="butt">
                        <button className="buttlink" onClick={() => window.open("https://codeforces.com/profile/gaandiv_07", "_blank")}>
                        <div className="icon-container"><SiCodeforces className="icon" />   </div>
                         <span>Codeforces</span>
                        </button>
                    </div>
                    <div className="butt">
                        <button className="buttlink" onClick={() => window.open("mailto:jyoshnaguravana@gmail.com", "_blank")}>
                        <div className="icon-container"><FiMail className="icon" /></div>  
                        <span>Mail me here</span>
                        </button>
                    </div>
                    <div className="butt">
                        <button className="buttlink" onClick={() => window.open("https://auth.geeksforgeeks.org/user/jyoshnaguravana", "_blank")}>
                        <div className="icon-container"><SiGeeksforgeeks className="icon" /></div>  
                        <span>GeeksForGeeks</span>
                        </button>
                    </div>
                    <div className="butt">
                        <button className="buttlink" onClick={() => window.open("https://www.hackerrank.com/profile/jyoshnaguravana", "_blank")}>
                        <div className="icon-container"><SiHackerrank className="icon" /></div> 
                        <span>Hackerrank</span> 
                        </button>
                    </div>
                    <div className="butt">
                        <button className="buttlink" onClick={() => window.open("https://www.linkedin.com/in/jothsna-guravana-350871206", "_blank")}>
                        <div className="icon-container"><FaLinkedin className="icon" /> </div> 
                        <span>LinkedIn</span>
                        </button>
                    </div>
                    <div className="butt">
                        <button className="buttlink" onClick={() => window.open("https://github.com/jyothsnaguravana", "_blank")}>
                        <div className="icon-container"> <FaGithub  className="icon" /> </div>  <span>GitHub</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Skills;



            {/* <a href={project.link} target="_blank" rel="noopener noreferrer">
              Go to project
            </a> */}