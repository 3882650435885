import React, { useState } from "react";
import data from "./proj.json";
import './project.css';

const Projects = () => {
  const [openwin, setOpenwin] = useState(false);
  const [p, setP] = useState(null);

  const handleClick = (project) => {
    setOpenwin(true);
      setP(project);
  }
  const handleClose = () => {
    setOpenwin(false);
  }

  return (
    <div>
        {
      !openwin ? (
        <div className="p-container">
        <div className="p-wrapper">
          {data.map((project, index) => (
            <div className="card2" key={index}>
              <h3>{project.name}</h3>
              <button className="but" onClick={() => handleClick(project)}>About</button>
            </div>
          ))}
        </div>
        </div>
      ) : (
          <div className="pop-up-container">
          <div className="pu-wrapper">
              <h3>{p.name}</h3>
              <div className="abt">
                  {p.detail}
              </div>
              <div className="links">
                  <button className="buttlink" onClick={() => window.open(`${p.link}`, "_blank")}>              
                      View Project </button>
                  <button className="buttlink" onClick={() => handleClose()}>Close</button>
              </div>
          </div>
        </div>
      )
    }
    </div>   

    
  );
};

export default Projects;
