import React from "react"; 
import "./acheive.css";
import {AiFillLinkedin,AiFillGithub} from "react-icons/ai";
import grp from "./images/grp_photo.jpeg";
import merit from "./images/merit.jpeg";
import sih from "./images/sih_win.jpeg";

const Acheive = () => {
    return (
        <div className="a-container">
            <div className="heading">My <span className="pinkText">Achievements</span></div>
            <div className="parent">
                <div className="a-card">
                    <div className="a-left">
                        <div className="desc">
                            <span className="text"><span className="plus">+</span> Our team Data Warriors secured first prize in <span className="diff">Smart India Hackathon -2022 5th Edition </span> with a whooping prize money of 50K INR.</span>
                            <span className="text"><span className="plus">+</span> Won first prize in the event Industrial Development Program help in national event - Stepcone - 2022.</span>
                            <span className="text"><span className="plus">+</span> Secured first prize in codethon 2 times conducted by ACM student chapter. </span>
                            <span className="text"><span className="plus">+</span> Got a merit scholarship award for yearly performance in college GMRIT .</span>
                        </div>
                        <div className="media">
                            <div><AiFillLinkedin size={35} /> </div>
                            <div><AiFillGithub size={35} /></div>
                        </div>
                    </div>
                    <div className="a-right">
                        <div className="image-container">
                            <img src={grp} alt="" />
                        </div>
                    </div>
                </div>
                <div className="extra">
                    <div className="image-left">
                        <img src={merit} alt="" />
                    </div>
                    <div className="image-right">
                        <img src={sih} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};
 
export default Acheive;