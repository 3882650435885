import React from "react"; 
import { NavLink } from "react-router-dom";
import "./main.css";

const Main = ({children}) => {
    const menu = [
        {
            path : "/portfolio/Home",
            name : "Home"
        },
        {
            path : "/portfolio/education",
            name : "Education"
        },
        {
            path : "/portfolio/skills",
            name : "Skills"
        },
        {
            path : "/portfolio/projects",
            name : "Projects"
        },
        {
            path : "/portfolio/Achievements",
            name : "Achievements"
        },
    ]
    return (
        <div className="container">
            <div className="flexCenter paddings innnerWidth navbar">
                <div className="left"> 
                    <span className="port">My Portfolio</span>
                </div>
                <div className="right">
                    <ul>
                     {
                        menu.map((i,index) => (
                           <li>
                              <NavLink to={i.path} className="linkto">{i.name}</NavLink>
                            </li>
                        ))
                     }
                    </ul>
                </div>
            </div>
            <main>{children}</main>
        </div>
    );
}

export default Main;