import React from "react"; 
import "./edu.css";

const Educate = () => {
    return (
        <div className="e-container">
            <div className="e-head">
                My <span className="pinkText">Education </span>
            </div>
            <div className="e-wrapper">
                <div className="card">
                    <div className="desg">Bachelor of Technology - Computer Science and Engineering  at GMR Institute of Technology , Rajam (2020 - 2024) </div> 
                    <div className="grade">CGPA - 9.42 </div>
                </div>
                <div className="card">
                    <div className="desg">Completed Senior Secondary Education at SriChaitanya jr. College,Vishakhapatnam  in Maths Stream (2018 - 2020)</div> 
                    <div className="grade">
                        <span className="g1">CGPA - 9.9 </span>|
                        <span className="g2">EAMCET RANK - 2419 </span>|
                        <span className="g3">JEE SCORE - 95.7 percentile </span>
                    </div>
                </div>
                <div className="card">
                    <div className="desg">Completed Secondary Education at GMR V DAV Public School , Rajam (2018) </div> 
                    <div className="grade">With 92.4 %</div>
                </div>
                <div className="pink1"></div>
                <div className="pink2"></div>
                <div className="pink3"></div>
                <div className="pink4"></div>
                <div className="pink5"></div>
                <div className="pink6"></div>
            </div>
        </div>
    );
};

export default Educate; 